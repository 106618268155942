import { get } from 'lodash';

export const isValidAmount = (amount) => {
  return /^[0-9]+?$/gm.test(amount) && parseInt(amount) >= 1;
};

export const queryStringToObject = (query) => {
  if (!query) {
    return {};
  }

  // Remove leading ? or #
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      let [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

export const objectToQueryString = (params) => {
  return Object.keys(params)
    .reduce((memo, key) => {
      return params[key]
        ? memo.concat([
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          ])
        : memo;
    }, [])
    .join('&');
};

export const get_user_check = (initialState) => {
  const is_member = !!get(initialState, 'details.email');

  return {
    is_member: () => is_member,
    details_complete: () =>
      is_member &&
      !!get(initialState, 'details.firstName') &&
      !!get(initialState, 'details.lastName')
  };
};

// add transaction fee from donation amount
export const add_trx_fee = (amount) => Math.ceil(amount * 1.029 + 0.3);

// remove transaction fee from donation amount with fee on top
export const remove_trx_fee = (amount) => Math.floor((amount - 0.3) / 1.029);

// get ootr (one off to regular) info: amounts and period
export const get_ootr_info = (qs_ootr, initial_amounts) => {
  //if (!qs_ootr) {
  return { amounts: ['1', '2', '3', '5', '8', '10'], period: 'weekly' };
  //}

  /*
  const periods = ['weekly', 'fortnightly', 'monthly'];
  const [amount_list, selected_period] = qs_ootr.split('|');

  const valid_period = periods.filter(item => item === selected_period).pop();
  const valid_amounts = amount_list
    .split(',')
    .map(amount => parseInt(amount))
    .filter(amount => !isNaN(amount));

  return {
    amounts: valid_amounts.length > 0 ? valid_amounts : initial_amounts,
    period: valid_period || 'weekly'
  };
  */
};
